.Home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HomeTitle {
  color: black;
}

.HomeTitle:hover {
  color: black;
  text-decoration: none;
}

.HomeJoinGameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
}

.HomeJoinGameTextField {
  flex: 1 1 50%;
}

.HomeJoinGameButton {
  flex: 1 1 50%;
  margin:7px;
  border-color: #f64f59;
}

.HomeNewGameButton {
  margin: 7px 7px;
  border-color: #12c2e9;
}

@media (max-width: 767.98px) {
  .HomeMainContainer {
    width: 90%;
  }

  .HomeTitle {
    font-size: 14vmin;
  }

  .HomeJoinGameButton {
    font-size: 5.8vmin;
  }

  .HomeNewGameButton {
    font-size: 6vmin;
  }
}

@media (min-width: 767.99px) {
  .HomeMainContainer {
    width: 40%;
  }

  .HomeTitle {
    font-size: 14vmin;
  }

  .HomeJoinGameButton {
    font-size: 6vmin;
  }

  .HomeNewGameButton {
    font-size: 6vmin;
  }
}
