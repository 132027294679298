.CanvasContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  position: absolute;
}

.CanvasHeader {
  width: 100%;
  height: 10%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  border-bottom: 1px solid #DDD;
}

.CanvasTimer {
  flex: 1;
  text-align: end;
  margin-right: 10px;
}

.CanvasCurrRound {
  flex: 1;
  text-align: start;
  margin-left: 10px;
}

.CanvasUnderlinedWord {
  white-space: pre-wrap;
}
  
.Canvas {
  width: 100%;
  height: 75%;
  background-color: white;
}

.CanvasDummy {
  width: 100%;
  height: 15%;
}

.CanvasControlsContainer {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  position:absolute
}

.CanvasColorsContainer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

.CanvasControl {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border-radius: 0px !important;
  border-color: #f64f59;
  user-select: none;
  outline: none;
}

.CanvasControl:hover {
  outline: none;
}

.CanvasControl:focus {
  outline: none;
}

.CanvasControl:active {
  outline: none;
}

.CanvasColorControl {
  width: auto;
  height: 50%;
  border-color: rgba(255, 255, 255, 0.56);
}

.CanvasControlsOverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: absolute;
  background-color: rgba(43, 83, 129, 0.5);
  cursor: not-allowed;
}

@media (max-width: 767.98px) {
  .CanvasHeader {
    font-size: 2vh;
  }
}

@media (min-width: 767.99px) {
  .CanvasHeader {
    font-size: 3vmin;
  }
}
