.Room {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RoomTitleLink {
  color: black;
}

.RoomTitleLink:hover {
  color: black;
  text-decoration: none;
}

.RoomMainContainer {
  display: flex;
}

.RoomContainerTitle {
  text-align: center;
  margin: 0;
  margin-top: 3px;
}

.RoomPlayersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.RoomPlayersList {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
}

.RoomPlayer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3px;
}

.RoomPlayerName {
  display: inline-block
}

.RoomGameControlsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  color: white;
}

.RoomPlayButton {
  border-color: #f64f59;
  margin: 0px 7px;
  margin-bottom: 5px;
  user-select: none;
  outline: none;
}

.RoomPlayButton:hover, .RoomPlayButton:focus {
  border-color: #c471ed;
  outline: none;
}

.RoomPlayButton:active {
  border-color: #12c2e9;
  outline: none;
}

.RoomPlayButton:disabled {
  background-color: #ddd;
  border-color: rgba(255, 255, 255, 0.38);
  color: rgba(0, 0, 0, 0.20);
  outline: none;
  cursor: not-allowed;
}

@media (max-width: 767.98px) {
  .RoomMainContainer {
    width: 90%;
    height: 75%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .RoomTitleLink {
    font-size: 12vmin;
    line-height: 15vmin;
  }

  .RoomGameCode {
    font-size: 6vmin;
  }

  .RoomContainerTitle {
    font-size: 10vmin;
  }

  .RoomPlayersContainer {
    width: 100%;
    height: 40%;
  }

  .RoomPlayerName {
    line-height: 4vmin;
    font-size: 4vmin;
  }

  .RoomPlayerTag {
    font-size: 3vmin;
  }

  .RoomGameControlsContainer {
    width: 100%;
    height: 60%;
  }
}

@media (min-width: 767.99px) {
  .RoomMainContainer {
    width: 80%;
    height: 60%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .RoomTitleLink {
    font-size: 14vmin;
  }

  .RoomGameCode {
    font-size: 6vmin;
  }

  .RoomContainerTitle {
    font-size: 12vmin;
  }

  .RoomPlayersContainer {
    width: 50%;
    height: 100%;
  }

  .RoomPlayerName {
    line-height: 4vmin;
    font-size: 4vmin;
  }

  .RoomPlayerTag {
    font-size: 3vmin;
  }

  .RoomGameControlsContainer {
    width: 50%;
    height: 100%;
  }
}
