.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ddd;
  font-size: 1.1rem;
  font-weight: 500;
}

.FooterText {
  margin: 0;
}

.FooterLink {
  color: black;
}

.FooterLink:hover {
  color: white;
}
