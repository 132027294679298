.App {
  background-color: black;
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100vw;
  height: 100vh;
  overscroll-behavior: contain;
}

.Button {
  background-color: white;
  margin: 5px;
  border-width: 3px;
  border-radius: 5px;
  font-size: 1.3rem;
  color: black;
  text-decoration: none;
}

.Button:hover {
  text-decoration: none;
}
