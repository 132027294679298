.Game {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GameTitleLink {
  color: black;
}

.GameGameCode {
  margin: 0px;
}

.GameTitleLink:hover {
  color: black;
  text-decoration: none;
}

.GamePlayContainer {
  display: flex;
}

.GameCanvasOverlayContainer {
  position: relative;
}

@media (max-width: 767.98px) {
  .GameTitleLink {
    line-height: 16vmin;
    font-size: 14vmin;
  }

  .GameGameCode {
    line-height: 7vmin;
    font-size: 5vmin;
  }

  .GamePlayContainer{
      width: 90%;
      height: 80%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .GameCanvasOverlayContainer {
    width: 100%;
    height: 60%;
  }

  .GamePlayersChatContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 767.99px) {
  .GameTitleLink {
    line-height: 18vmin;
    font-size: 14vmin;
  }

  .GameGameCode {
    line-height: 7vmin;
    font-size: 5vmin;
  }

  .GamePlayContainer{
    width: 80%;
    height: 70%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .GameCanvasOverlayContainer {
    width: 50%;
    height: 100%;
    z-index: 2;
  }

  .GamePlayersChatContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
  }

  .GameDummyPlayer {
    width: 20%;
    height: 100%;
  }

  .GameDummyChat {
    width: 30%;
    height: 100%;
  }
}
