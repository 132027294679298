.PlayersList {
    margin: 0px;
    display: flex;
}

.PlayersListPlayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PlayersListPlayerRankingIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
}

@media (max-width: 767.98px) {
    .PlayersList{
        width: 50%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .PlayersListPlayer {
        margin: 3px;
    }

    .PlayersListRanking {
        font-size: 4vmin;
    }

    .PlayersListPlayerName {
        font-size: 2.5vmin;
    }
}

@media (min-width: 767.99px) {
    .PlayersList{
        width: 20%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PlayersListPlayer {
        margin: 5px;
    }

    .PlayersListRanking {
        font-size: 3vmin;
    }

    .PlayersListPlayerName {
        line-height: 3vmin;
        font-size: 3vmin;
    }
}
